import React from "react";

export default function TopBar() {
  const [expand, setexpand] = React.useState(false);

  function setExpand(i) {
    setTimeout(() => {
      setexpand(i);
    }, 100);
  }

  return (
    <div className="top-bar">
      <div className="top-bar-md justify-content-between">
        <span>MBR IT Solutions</span>
        <span>
          <i className="fa-solid fa-phone"></i> +1 4255453034
          <i className="fa-solid fa-envelope mx-2"></i> admin@mbritsolutions.com
          <a
            href="https://www.facebook.com/Mbrit/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-facebook mx-2"></i>
          </a>
          <a href="https://admin.Mbrit.com" target="_blank" rel="noreferrer">
            <i className="fa-solid fa-lock mx-2"></i>
          </a>
        </span>
      </div>
      <div className="top-bar-sm text-center">
        {expand && (
          <div>
            MBR IT Solutions
            <br />
            <i className="fa-solid fa-phone"></i> +1 4255453034
            <i className="fa-solid fa-envelope mx-2"></i>{" "}
            admin@mbritsolutions.com
            <br />
            <a
              href="https://www.facebook.com/Mbrit/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-facebook mx-2"></i>
            </a>
            <a href="https://admin.Mbrit.com" target="_blank" rel="noreferrer">
              <i className="fa-solid fa-lock mx-2"></i>
            </a>
          </div>
        )}
        <div
          className="text-center"
          style={{ cursor: "pointer" }}
          onClick={() => setExpand(!expand)}
        >
          <i
            className={
              !expand ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"
            }
          ></i>
        </div>
      </div>
    </div>
  );
}
