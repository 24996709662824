import React from "react";
import axios from "./components/Axios.js";
import { Routes, Route, useNavigate } from "react-router-dom";

// Common Components
import TopBar from "./components/common/TopBar";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Copyright from "./components/Copyright";

//Pages
import Home from "./components/home/Home";
import About from "./components/about/About";
import Projects from "./components/projects/Projects";
import Services from "./components/services/Services";
import Internships from "./components/internships/Internships";
import Careers from "./components/careers/Careers";
import Contact from "./components/contact/Contact";

function App() {
  React.useEffect(() => {
    axios
      .get("/")
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <TopBar />
      <Navbar />
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Projects />} />
        <Route path="/services/*" element={<Services />} />
        <Route path="/internships" element={<Internships />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
      <Copyright />
    </>
  );
}

export default App;
