import React from "react";

export default function AboutDetail() {
  return (
    <div className="container mb-5 mt-5">
      <div className="row align-items-center">
        <div className="col-12">
          <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
            <h1 className="text-uppercase mb-3">About Us</h1>
            <p style={{ fontSize: "1rem", color: "black" }}>
              MBR IT Solutions is a Private incorporated on XXXXXXXXXXXXXX. It is
              classified as Non-govt Company and is registered at Registrar of
              Companies . It is involved in Software publishing,
              consultancy and supply
              <ul className="m-3">
                <li>
                  Software publishing includes production, supply and
                  documentation of ready-made (non-customized) software,
                  operating systems software, business & other applications
                  software, computer games software for all platforms.
                </li>
                <li>
                  Consultancy includes providing the best solution in the form
                  of custom software after analyzing the user's needs and
                  problems.
                </li>
                <li>
                  Custom software also includes made-to-order software based on
                  orders from specific users. Also, included are writing of
                  software of any kind following directives of the users;
                  software maintenance, web-page design
                </li>
              </ul>
              We are the supplier of Custom Application Development and start to
              end IT Services to customers globally. We utilize a customer
              driven commitment model that combines local and off-site assets
              with the cost, worldwide aptitude and quality preferences of
              seaward activities. We convey Custom Application Development,
              Application Modernization, Business Process Outsourcing and
              Professional IT Services. Develop custom software solutions using
              the latest technologies Advanced enterprise application
              development Hire experienced engineers, designers, and developers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
