import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function WebDevelopment() {
    const navigate = useNavigate();
    return (
        <div className="row">
            <div className="col-12 mb-3">
                <span className="btn btn-primary btn-sm mb-5" onClick={() => navigate("/services")} style={{ float: "right" }}>Back</span>
                <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
                    <h3 className="text-uppercase mb-3">1. Web Development</h3>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        MBR IT is a web site design and development firm
                        specializing in professional custom web site design, Ecommerce
                        website design, and internet marketing solutions for growing
                        companies. We provide a full range of professional web design
                        services to build your site and drive traffic to it. This
                        includes Flash animations, E-Commerce development, maintenance,
                        web hosting, search engine marketing.
                        <br />
                        Our focus is to provide businesses across the world with world
                        class web based solutions to enhance and compliment business
                        needs. We use advantages of global locations. Our development
                        center is in India to minimize costs. MBR IT expertise
                        guarantees quality, competent and rapid execution of any
                        projects - from small websites to complex ecommerce software and
                        custom solution programming.
                    </p>
                </div>
            </div>
            <div className="col-md-6 col-12 mb-3">
                <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
                    <h4 className="text-uppercase mb-3">
                        Website Design/Development
                    </h4>
                    <p style={{ fontSize: "1.0rem", color: "black" }}>
                        A truly well designed website can turn into a strong marketing
                        tool, the primary objective of which is to accentuate your
                        company's identity, thus enabling you to differentiate from your
                        competition and making your business succeed in our highly
                        competitive biz world. By addressing our highly-qualified team
                        of professionals you will attain a full spectrum of website
                        services. <br />
                        Deploying the most advanced technological tools, our team of
                        professionals is equipped to provide you with fresh and creative
                        design and graphic solutions, giving your website a distinctive
                        and eye-catching look. Our designs are user-focused and easy to
                        navigate. We aim at the creation of well-balanced websites,
                        which presupposes harmonious combination of many essential
                        constituents:
                        <ul>
                            <li>General Layout</li>
                            <li>Color Compatibility</li>
                            <li>Space Usage</li>
                            <li>Easy Navigation</li>
                            <li>User Friendliness</li>
                        </ul>
                        At close cooperation with our design experts you will be able to
                        put your ideas into view and create memorable images of your
                        business on the web. Morever having a solid experience in web
                        development we can empower your website with a comprehensive
                        functionality (front-end and back-end) turning it from just a
                        presentation to a robust business tool.
                    </p>
                </div>
            </div>
            <div className="col-md-6 col-12 mb-3">
                <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
                    <h4 className="text-uppercase mb-3">
                        Word Press customization, set up and integration services
                    </h4>
                    <p style={{ fontSize: "1.0rem", color: "black" }}>
                        WordPress is a popular Content Management System (CMS).written
                        in PHP programming language supported by MySQL database. This is
                        open source CMS which has many features like customization of
                        the themes, categories, templates, link management, plug-ins,
                        widget, tags, etc. . It makes the Content Management System
                        based websites cost and time effective. MBR IT provides
                        fully professional & affordable CMS integration. Our development
                        team provides CMS integration as well as customization service
                        according to your needs and requirements. We will provide you
                        fully elegant and customized site integrating with any Open
                        Source CMS. Low development/maintenance cost and quick
                        turnaround makes CMS a highly recommended solution for small and
                        mid-sized websites which can then afford to have a good
                        marketing budget to reach out to their target audience.
                    </p>
                </div>
            </div>
        </div>
    )
}
