import axios from "axios";

if (document.location.hostname === "localhost") {
  axios.defaults.baseURL = "http://localhost:5000";
}
else if (document.location.hostname === "mbritsolutions.com") {
  axios.defaults.baseURL = "https://mbrit-server.onrender.com";
}
else {
  axios.defaults.baseURL = "https://mbrit-server.onrender.com";
}

export default axios;