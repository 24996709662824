import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function GlobalSourcing() {
    const navigate = useNavigate();
    return (
        <div className='row'>
            <div className="col-12 mb-3">
                <span className="btn btn-primary btn-sm mb-5" onClick={() => navigate("/services")} style={{ float: "right" }}>Back</span>
                <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
                    <h3 className="text-uppercase mb-3">4. Global Sourcing</h3>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        Global Sourcing is a specialist service that has been developed
                        to address the needs of global businesses looking at employing
                        Indian & Expatriate talent at leadership / senior and middle
                        management levels outside India. Mandates are delivered through
                        an expert consultant team, which focusses only on such
                        assignments. With our research driven methodology, strong
                        linkages within the Indian & global talent pool and our sourcing
                        skills, we would be in a position to add significant value to
                        your hiring process.
                        <br />
                        Global Companies are looking at hiring talent from India,
                        knowing the versatility and professional competence of Indian
                        talent that has been demonstrated and proven, beyond doubt in
                        the recent years. Domain knowledge, multi -tasking, ability to
                        adapt and experience of having worked in a complex Indian market
                        are the factors that prompt global organizations to look for
                        Indian talent. MBR IT is well positioned to cater to the
                        needs of this segment given its pioneering and dominating
                        presence in Executive Search & Selection in India.
                    </p>
                </div>
            </div></div>
    )
}
