import React from "react";
import { useNavigate } from "react-router-dom";
import I1 from "../../assets/img/about.png";

export default function AboutHome() {
  const path = window.location.pathname;
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <img className="img-fluid mb-4 mb-lg-0" src={I1} alt="" />
        </div>
        <div className="col-lg-6 aboutus">
          <small>About Us</small>
          <h1 className="mb-4">
            Welcome to MBR IT:
            <small>(MBR IT Solutions)</small>
          </h1>
          <p className="mb-4">
          Our AI-Powered DevOps platform unifies, secures, and generates predictive insights across the software lifecycle to enhance business value. We are a team of passionate and experienced professionals who are committed to providing the best IT solutions to our clients. We are a team of passionate and experienced professionals who are committed to providing the best IT solutions to our clients.
          </p>
          {path === "/" && (
            <button
              onClick={() => handleNavigate("/about")}
              className="btn btn-primary text-uppercase py-3 px-5 mb-5"
            >
              <b>Read More</b>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
