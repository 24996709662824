import React from "react";
import axios from "../Axios.js";

export default function Contact() {
  const [Alert, setAlert] = React.useState("");
  const path = window.location.pathname;
  const [id, setId] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [perc, setPerc] = React.useState(0);
  const [data, setData] = React.useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: "",
    Attachment: "",
  });

  const handleChange = (e) => {
    setAlert("");
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.Name && data.Message && data.Phone.length === 10) {
      axios
        .post("/query/add", data)
        .then((res) => {
          console.log(res);
          if (res.data.id) {
            setAlert("");
            setId(res.data.id);
          }
          setData({
            Name: "",
            Email: "",
            Phone: "",
            Message: "",
            Attachment: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAlert("Please enter valid details");
    }
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (file) {
      setPerc(perc + 10);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("location", "query");
      setPerc(perc + 20);
      try {
        const res = await axios.post(
          "/file/upload",
          formData
        );
        setPerc(100);
        setData((prev) => {
          return {
            ...prev,
            Attachment: res.data.Location,
          };
        });
        setTimeout(() => {
          setPerc(0);
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    } else {
      setAlert("Please select a file");
    }
  };

  return (
    <div className="container py-5 px-0">
      {path === "/" && (
        <h1 className="display-4 text-uppercase text-center mb-5">
          Contact Us
        </h1>
      )}
      <div className="row mx-0 justify-content-center">
        <div
          className="col-md-6 col-12 p-md-5 p-3 pb-5 "
          style={{ height: "500px" }}
        >
          <div className="position-relative h-100">
            <iframe
              className="position-relative w-100 h-100"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41167424.34469571!2d-132.08532758867793!3d31.786060306224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e1!3m2!1sen!2sin!4v1680939295217!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen
              aria-hidden="false"
              tabIndex={0}
              frameBorder={0}
            />
          </div>
        </div>

        <div className="col-md-6 col-12 p-md-5 p-3 py-5">
          <div className="contact-form bg-white rounded">
            {Alert && (
              <div class="alert alert-primary text-center" role="alert">
                {Alert}
              </div>
            )}
            {id && (
              <div class="alert alert-primary text-center" role="alert">
                Thanks for contacting us. <br />
                Your Query ID is <b>{id}</b>
                <br />
                We will get back to you soon.
              </div>
            )}
            <form>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="control-group">
                    <input
                      type="text"
                      name="Name"
                      value={data.Name}
                      onChange={handleChange}
                      className="form-control bg-light border-0 p-4"
                      placeholder="Your Name"
                      required
                    />
                    <p className="help-block text-danger" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="control-group">
                    <input
                      type="number"
                      name="Phone"
                      value={data.Phone}
                      onChange={handleChange}
                      className="form-control bg-light border-0 p-4"
                      placeholder="Your Phone"
                      required
                    />
                    <p className="help-block text-danger" />
                  </div>
                </div>
              </div>
              <div className="control-group">
                <input
                  type="email"
                  name="Email"
                  value={data.Email}
                  onChange={handleChange}
                  className="form-control bg-light border-0 p-4"
                  placeholder="Your Email"
                  required
                />
                <p className="help-block text-danger" />
              </div>
              <div className="control-group">
                <textarea
                  className="form-control bg-light border-0 py-3 px-4"
                  rows={5}
                  name="Message"
                  value={data.Message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  required
                />
                <p className="help-block text-danger" />
              </div>
              <label>Attachment (If any)</label>
              <div className="control-group mb-2 contact-image-upload">
                {perc !== 0 ? (
                  <div className="progress mb-3" style={{ width: "100%" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: perc + "%" }}
                      aria-valuenow={perc}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      {perc}%
                    </div>
                    <p></p>
                  </div>
                ) : null}
                {!data.Attachment && (
                  <div className="row">
                    <span className="col-8">
                      <label
                        className="fa fa-solid fa-upload"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          className="form-control btn btn-outline-primary"
                          type="file"
                          onChange={handleFile}
                          name="resume"
                          accept="application/pdf application/msword"
                          hidden
                        />
                        <span className="ml-2">
                          {file ? file.name : "Choose a file"}
                        </span>
                      </label>
                    </span>
                    <span className="col-4">
                      <button
                        onClick={handleFileUpload}
                        className="btn btn-primary"
                        style={{ float: "right" }}
                      >
                        Upload
                      </button>
                    </span>
                  </div>
                )}
                {data.Attachment && (
                  <div className="row">
                    <div class="col-12 alert alert-primary" role="alert">
                      <span style={{ float: "left" }}>
                        File Uploaded Successfully
                      </span>
                      <span style={{ float: "right" }}>
                        <a
                          className="btn btn-primary btn-sm"
                          href={data.Attachment}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Preview
                        </a>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary text-uppercase py-3 px-5"
                  onClick={handleSubmit}
                >
                  <b>Send Message</b>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
