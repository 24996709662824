import React from "react";

export default function Copyright() {
  return (
    <>
      <div
        className="container-fluid py-4 px-sm-3 px-md-5"
        style={{ background: "#111111" }}
      >
        <p className="mb-2 text-center text-white-50">
          © 2007 -22 | MBR IT Solutions | All Rights Reserved.
        </p>
      </div>
    </>
  );
}
