import React from "react";
import axios from "../components/Axios.js";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/icon/logo.svg";

export default function Footer() {
  const [Alert, setAlert] = React.useState("");
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  const [data, setData] = React.useState({
    email: "",
  });

  const handleChange = (e) => {
    setAlert("");
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      data.email !== ""
    ) {
      axios
        .post("/newsletter/add", data)
        .then((res) => {
          console.log(res);
          setAlert(res.data.message);
          setData({ email: "" });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAlert("Please enter a valid email");
    }
  };

  const Links = [
    { name: "Home", link: "/" },
    { name: "About Us", link: "/about" },
    { name: "Products", link: "/products" },
    { name: "Services", link: "/services" },
    { name: "Internships", link: "/internships" },
    { name: "Careers", link: "/careers" },
    { name: "Contact Us", link: "/contact" },
  ];

  return (
    <div
      className="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-md-5"
      style={{ marginTop: "90px" }}
    >
      <div className="row pt-5">
        <div className="col-lg-3 col-md-6 mb-5">
          <a href="index.html" className="navbar-brand">
            <img src={Logo} alt="logo" style={{ height: "80px" }} />
          </a>
          <br />
          <br />
          <p>
            <b>MBR IT Solutions LLC</b> is a
            Private incorporated on XXXXXXXXXX. It is inolved in Software
            publishing, consultancy and supply <br />
            <b>CIN: </b>U72XXXXXXXXXXXXX
            <br />
          </p>
          <h6 className="text-uppercase text-white py-2">Follow Us</h6>
          <div className="d-flex justify-content-start">
            <a className="btn btn-lg btn-primary btn-lg-square mr-2" href="https://www.facebook.com/Mbrit/" target={"_blank"} rel="noreffer">
              <i className="fab fa-facebook-f" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-5">
          <h4 className="text-uppercase text-white mb-4">Get In Touch</h4>
          <p>MBR IT Solutions LLC</p>
          <p>
            <i className="fa fa-map-marker-alt text-white mr-2" />
            #This is the Adress of MBR IT Solutions
          </p>
          <p>
            <i className="fa fa-phone-alt text-white mr-2" />
            +1 4255453034
          </p>
          <p>
            <i className="fa fa-envelope text-white mr-2" />
           admin@mbritsolutions.com
          </p>
        </div>
        <div className="col-lg-2 col-md-6 mb-5">
          <h4 className="text-uppercase text-white mb-4">Quick Links</h4>
          <div className="d-flex flex-column justify-content-start">
            {Links.map((link, index) => {
              return (
                <a
                  href="javascript:void(0)"
                  onClick={() => handleNavigate(link.link)}
                  className="text-white-50 mb-2"
                  style={{ float: "left" }}
                  key={index}
                >
                  <i className="fa fa-angle-right text-white mr-2" />
                  {link.name}
                </a>
              );
            })}
            <a
              href="https://Mbrit.org"
              className="text-white-50 mb-2"
              style={{ float: "left" }}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <i className="fa fa-angle-right text-white mr-2" />
              Blog
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-5">
          <h4 className="text-uppercase text-white mb-4">Newsletter</h4>
          <p className="mb-4">
            Get latest updates and offers information. Don't worry, we won't
            send spam!
          </p>
          <div className="w-100 mb-3">
            <div className="input-group">
              <input
                type="text"
                name="email"
                value={data.email}
                onChange={handleChange}
                className="form-control border-light"
                style={{ padding: "25px" }}
                placeholder="Your Email"
              />
              <div className="input-group-append">
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary text-uppercase px-3"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
          <i>{Alert}</i>
        </div>
      </div>
    </div>
  );
}
