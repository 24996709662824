import React from "react";
import { useNavigate } from "react-router-dom";

export default function ServicesComplete() {
  const navigate1 = useNavigate();

  const navigate = (params) => {
    navigate1(params);
    window.scrollTo(0, 0);
  };

  const services = [
    {
      id: 1,
      name: "Web Development",
      description: "MBR IT is a web site design and development firm specializing in professional custom web site design, Ecommerce website design, and internet marketing solutions for growing companies. We provide a full range of professional web design services to build your site and drive traffic to it",
      img: "https://miro.medium.com/max/1200/0*M4bxiCIjcTK-2Xr6.jpeg",
      params: "web-development",
    }, {
      id: 2,
      name: "Mobile App Development",
      description: "MBR IT has emerged as a leading provider of Mobile Applications over the last few years by consistently enhancing the competencies that are vital to introduce innovation in the one technology which has become the center stage in today's world — the rapidly growing mobile space.",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaemd1JU49HT1GPmhFXwgFl9NsrHMbaPLYl9MeIXcCg4CLYEwsCq7LMOSZp-KX2LmT0ys&usqp=CAU",
      params: "mobile-apps",
    },
    {
      id: 3,
      name: "Consulting",
      description: "We offers services to streamline IT strategy creating, information security assurance and system integration, ensure smooth and effective digital transformation and improve digital customer experience. Our approach is to focus on the client’s strategy and long-term goals,",
      img: "https://reputationtoday.in/wp-content/uploads/2020/11/consulting-jobs.png",
      params: "consulting"
    }, {
      id: 4,
      name: "Global Sourcing",
      description: "Global Sourcing is a specialist service that has been developed to address the needs of global businesses looking at employing Indian & Expatriate talent at leadership / senior and middle management levels outside India.",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-QReCiozyfg7Mz8LOsM7lYfb6By3o4sf9fA&usqp=CAU",
      params: "global-sourcing"

    },
    {
      id: 5,
      name: "Corporate Training",
      description: "MBR IT is a leading provider of corporate training services. We offer a wide range of training programs to help you and your employees develop the skills and knowledge needed to succeed in today's competitive business environment.",
      img: "https://theyellowspot.com/wp-content/uploads/2019/07/corporate-training.jpg",
      params: "corporate-training"
    }, {
      id: 6,
      name: "Staffing",
      description: "We provide staffing solutions to help you find the right people for your business. Our staffing services include temporary staffing, temporary-to-hire staffing, direct hire staffing, and professional search.",
      img: "https://www.roberthalf.ca/sites/roberthalf.ca/files/styles/full_width_content_image_1x_small_480/public/2018-01/staffing-plan-rh-10-07-2016.jpg?itok=FgK94Iow",
      params: "staffing"
    }
  ]
  return (
    <>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
            <h1 className="text-uppercase mb-3">Our Services</h1>
            <p style={{ fontSize: "1rem", color: "black" }}>
              Our team of technology and process experts gathers insight and
              information from your enterprise to implement business solutions
              that yield quick and sustainable returns. Our end-to-end
              solutions can empower your organization to reach key strategic
              objectives
              <br />
              Our services supplement to define, optimize and support our
              benefactor's business strategy with technology advantage.
              <br /> An in-depth knowledge of various technological areas such
              as enterprise IT programs, communications and Internet
              technology, product development and engineering, product design
              and data management across various domains facilitate us to
              satisfy our clients specific requirements. <br />
              We maximize the benefits with our 'Web Participation' of our
              depth, diversity and delivery capability. Ensuring adaptability
              to client needs, in consequence reveal the most pioneering
              solution in every business and technological domain.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        {services.map((service, index) => {
          return (
            <div className="col-md-4 col-12">
              <div className="card mb-3">
                <img src={service.img} className="card-img-top" height={"200px"} />
                <div className="card-body">
                  <h5 className="card-title">{index + 1}. {service.name}</h5>
                  <p className="card-text">{service.description}<button onClick={() => navigate(`/services/${service.params}`)} className="card-link"><b>Read More..</b></button></p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  );
}
