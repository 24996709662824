import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Staffing() {
    const navigate = useNavigate();
    return (
        <div className='row'>
            <div className="col-12 mb-3">
                <span className="btn btn-primary btn-sm mb-5" onClick={() => navigate("/services")} style={{ float: "right" }}>Back</span>
                <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
                    <h3 className="text-uppercase mb-3">6. Staffing:</h3>
                    <h6>(i) Permanent Staffing</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        With niche experience MBR IT is dedicated to deliver best
                        practices to its clients through Permanent Staffing. We support
                        Client needs which will enable to improve visibility, quality
                        and control of talent pipeline through best offerings.
                    </p>
                    <h6>(ii) Entry Level Recruitment</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        MBR IT Focus is more in to Graduate Hiring and
                        Undergraduate hiring to provide innovative training and
                        development, Mentoring and flexible work programs to become lead
                        in the Market.
                    </p>
                    <h6>(iii) Lateral Hiring</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        MBR IT recognize that each industry has its own mold in
                        terms of management style, Best Hiring practices and
                        compensation norms. MBR IT has developed expertise in key
                        segments like Industrial, Logistics, Healthcare, Services,
                        Technology, Products and many more to provide best team in the
                        market.
                    </p>
                    <h6>(iv)Employee Leasing</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        MBR IT aim is to help clients with existing staff needs.
                        To build a long lasting relationship, we deliver dynamic
                        benefits that will help clients to stay future proof.
                    </p>
                    <h6>(iv)Recruitment Process Outsourcing</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        MBR IT will take full responsibility for every stage of
                        the hiring process. Tailoring our techniques to suit the needs
                        of business, and work closely with team to find the best quality
                        candidates within shorter timescales
                    </p>
                </div>
            </div>
        </div>
    )
}
