import React from "react";

export default function AboutContact() {
  const Links = [
    {
      name: "Our Office",
      text: "#This is the Adress of MBR IT Solutions",
      icon: "fa-map-marker-alt",
    },
    {
      name: "Phone",
      text: "+1 4255453034",
      icon: "fa-phone-alt",
    },
    {
      name: "Email",
      text: "admin@mbritsolutions.com",
      icon: "fa-envelope",
    },
  ];

  return (
    <div className="container mt-5">
      <div className="row">
        {Links.map((link, index) => {
          return (
            <div className="col-lg-4 mb-2" key={index}>
              <div
                className="d-flex align-items-center bg-light rounded mb-4 px-md-5 px-3 py-3"
                style={{ height: "200px" }}
              >
                <i className={`fa fa-3x ${link.icon} text-primary mr-3`} />
                <div className="d-flex flex-column">
                  <h5 className="text-uppercase">{link.name}</h5>
                  <p className="m-0">{link.text}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
