import React from "react";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const navigate = useNavigate();
  return (
    <div id="carouselExampleInterval" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active first" data-interval={5000}>
          <div class="slide-content">
            <h1 className="text-uppercase text-white">Welcome to MBR IT Solutions LLC:</h1>
            <p>
              We Provide IT Consulting Services, Web Development, Mobile Development, E-Commerce Development, Web Designing, Staffing Services, and Product Development.<br />
              <button onClick={() => navigate("/about")} className="btn btn-dark text-uppercase mt-1 py-2 px-3 my-4">
                Learn More
              </button>
            </p>
          </div>
        </div>
        <div className="carousel-item second" data-interval={2000}>
          <div class="slide-content text-center">
            <p>
              1000 Projects is one of the fast-growing multi-discipline companies based in Hyderabad, Incorporated in 2014, 1000 Projects had grown consistently and established operations globally. delivering high-quality IT solutions to our clients globally.
            </p>
          </div>
        </div>
        <div className="carousel-item third">
          <div class="slide-content text-center">
            {/* <h1 className="text-uppercase text-white">Our Mission and Vision</h1> */}
            <p>
              It is Software Product development, Software services, and delivery for Retail, Telecom and Technology businesses Company.<br />

              It is committed to offering world-class services to its clients across the globe in the areas of application software design, development, testing, deployment, and maintenance services.
            </p>
          </div>
        </div>
      </div>
      <a className="carousel-control-prev carousel-icon" href="#carouselExampleInterval" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next carousel-icon" href="#carouselExampleInterval" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
}

{/* <div className="hero" style={{ marginBottom: "20px" }}>
      <div style={{ textAlign: "center" }}>
        <h6>Welcome to</h6>
        <h1 className="display-2">MBR IT</h1>
        <p>
          Leading Software publishing, consultancy and supply company.
        </p>
        <button onClick={()=>navigate("/about")} className="btn btn-dark text-uppercase mt-1 py-3 px-5">
          Learn More
        </button>
      </div>
    </div> */}