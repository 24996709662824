import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../Axios.js';
import ProjectCard from './ProjectCard';

export default function ProjectsHome() {
    const [project, setProject] = React.useState([]);
    const [len, setLen] = React.useState(null);
    const navigate = useNavigate();

    const handleNavigate = (link) => {
        navigate(link);
        window.scrollTo(0, 0);
    }

    React.useEffect(() => {
        axios.get("/project/retrieveAll").then((res) => {
            setProject(res.data);
            setLen(res.data.length);
        });
    }, []);

    return (
        <div className="container internship py-5">
            <h1 className="display-4 text-uppercase text-center mb-5">
                {project && ("Our Recent Projects")}
            </h1>
            <div className="row justify-content-center">
                {project[len - 1] && <ProjectCard
                    item={project[len - 1]} />}
                {project[len - 2] && <ProjectCard
                    item={project[len - 2]} />}
                {project[len - 3] && <ProjectCard
                    item={project[len - 3]} />}
            </div>
            <div className='text-center mt-5'>
                <button
                    onClick={() => handleNavigate("/projects")}
                    className="btn btn-primary text-uppercase py-3 px-5"
                >
                    <b>More Projects</b>
                </button>
            </div>
        </div>
    )
}
